import React, { useState, useEffect } from 'react';

import { Row, Col, Image } from 'react-bootstrap';


const Steps = () => {
  // Estado para gestionar el estilo de fondo
  const [backgroundStyle, setBackgroundStyle] = useState({
    backgroundImage: 'url("/images/steps.png"), linear-gradient(180deg, rgba(0, 19, 56, 0) 70%, #001338 30%)',
    backgroundBlendMode: 'darken',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
  });

  useEffect(() => {
    // Función para actualizar el estilo basado en el ancho de la ventana
    const updateBackground = () => {
      if (window.innerWidth < 576) {
        setBackgroundStyle({
          backgroundImage: 'none',
          backgroundColor: '#001338'
        });
      } else {
        setBackgroundStyle({
          backgroundImage: 'url("/images/steps.png"), linear-gradient(180deg, rgba(0, 19, 56, 0) 70%, #001338 30%)',
          backgroundBlendMode: 'darken',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
        });
      }
    };

    // Escuchar cambios en el tamaño de la ventana
    window.addEventListener('resize', updateBackground);

    // Llamar a updateBackground al montar el componente para establecer el estado inicial
    updateBackground();

    // Limpiar el event listener al desmontar el componente
    return () => window.removeEventListener('resize', updateBackground);
  }, []); // El array vacío asegura que el efecto se ejecute solo una vez al montar


  return (
    <div className='g-0 'fluid  id="how-it-works" style={backgroundStyle} >
        <div className='g-0'>
          <Image src='/images/steps.png' alt='stepbg' className='d-block d-md-none img-fluid'></Image>
        </div>
      <Row className="justify-content-md-center align-items-end text-center g-0 ">
        <Col xs={12} md={4} className="step-col mt-md-5 ">
          <img src="/images/step1.png" alt="Paso 1" />
        </Col>
        <Col xs={12} md={4} className="step-col  ">
          <img src="/images/step2.png" alt="Paso 2" className=''/>

        </Col>
        <Col xs={12} md={4} className="step-col">
          <img src="/images/step3.png" alt="Paso 3" />
        </Col>
      </Row>
      <Row className="justify-content-md-center text-center g-0">
        <Col>
          <h2 className='my-4 text-block text-white fw-bold px-3'><span className='color-step'>TU ALIADO</span> EN EL TRASLADO DE VEHÍCULOS</h2>
          <p className='text-block text-white mb-0 px-3'>¡Cobertura Completa en Estados Unidos, Puerto Rico y Centro America!</p> 
          <p className='text-block text-white px-5'>¡Contáctanos para una Consulta¡</p>
          <a href='tel:+18704556742' className='mb-5 btn btn-primary bg-button2 text-center text-block'>+1 (870-455-6742)</a>
        </Col>
      </Row>
    </div>
  );
};

export default Steps;