import React from 'react';
import { Row, Col, Card} from 'react-bootstrap';
import QuoteForm from './QuoteForm';

function Home() {

  const backgroundStyle = {
    backgroundImage: 'url("/images/home.png")',
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh' // This ensures that the column is at least the height of the viewport
  };

  // Define the overlay content style object
  const overlayContentStyle = {
    position: 'relative',
    zIndex: 1,
    // Add more styling as needed
  };

  return (
    <div >
      <Row className="g-0" id='home'>
        <Col xs={12} md={5} className="d-flex align-items-center justify-content-center" style={{ backgroundColor: '#0B2278' }}>
        <div className='d-flex flex-column justify-cotent-between px-5' >
            <h1 style={{ fontSize: '3rem' }} className='ms-4 fw-bold text-white text-block mt-5'>¡TRANSPORTA TU VEHICULO CON <span className='color-home'>FACILIDAD!</span></h1>
            <p style={{ fontSize: '1.5rem' }} className='ms-4 text-white text-block mb-4'>¡Rápidos, Seguros y al Mejor Precio!</p>
            <a href="tel:+18704556742" className='btn btn-outline-primary text-white ms-md-4 me-md-5 me-2 ms-2 py-md-3 py-3 mb-5' style={{backgroundColor: '#009ED8', fontWeight: '600'}}>¿Tienes dudas? Llamanos al +1 870-455-6742</a>
          </div>
        </Col>
      <Col xs={12} md={7} style={backgroundStyle} className='d-flex align-items-center justify-content-center d-none d-md-flex p-5' id="form">
        <div style={overlayContentStyle} className='p-5'>
          <Card style={{background: "linear-gradient(127deg, #1378D4 -5.42%, #01319F 89.43%)"}} className='p-5 '>
            <QuoteForm></QuoteForm>
          </Card>
        </div>
      </Col>
      <Col xs={12} md={6} style={{ backgroundColor: '#0B2278', marginTop: '-1px'}} className=' d-flex align-items-center justify-content-center d-block d-md-none' id="form">
        <div style={overlayContentStyle} className='py-4 mb-5'>
          <Card style={{background: "linear-gradient(127deg, #1378D4 -5.42%, #01319F 89.43%)"}} className='p-5 '>            <QuoteForm></QuoteForm>
          </Card>
        </div>
      </Col>
      </Row>
    </div>
  );
}

export default Home;