import React from 'react';
import { Container, Navbar, Nav} from 'react-bootstrap';
import { useCustomNavigate } from '../utils/customNavigation';

function NavComp() {
  const customNavigate = useCustomNavigate();
  return (
    <Navbar collapseOnSelect expand="md" id='nav' className='bg-navbar g-0' sticky="top">
      <Container>
       
        <Navbar.Brand  onClick={() => { customNavigate('#form')}}>
          <img
            src="/images/logo.svg" 
            width="100px"
            height="58px"
            className="d-inline-block align-top"
            alt="Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" className="custom-toggler" style={{ color: 'white' }} />
        <Navbar.Collapse id="responsive-navbar-nav" >
          <Nav className="ms-auto">
            <Nav.Link onClick={() => { customNavigate('#form')}} className='text-white ms-3'>Cotiza Ya</Nav.Link>
            <Nav.Link onClick={() => { customNavigate('#how-it-works')}} className='text-white ms-3'>¿Como Funciona?</Nav.Link>
            <Nav.Link href="/terms" className='text-white ms-3'>Terminos</Nav.Link>
            <Nav.Link  onClick={() => { customNavigate('#form')}} className='text-white ms-3'>Obten una cotizacion</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavComp;