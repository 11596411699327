import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';

const ImageStack = () => {
  return (
    <>
      <div className='g-0 bg-imagestack d-none d-md-block' id='imagestack'>
        <Row className="align-items-center g-0">
          <Col md={4} xs={12}>
            <div className="text-block px-4">
            <h3 className='color-stack text-block justify-text mb-5 mt-md-0 mt-5' ><span className='fw-bold'>¿</span>POR QUE CON <span className='fw-bold'>MUEVEMICARRO?</span></h3>
              <h3 className='color-stack text-block mt-2 fw-bold'>Rapido & Facil</h3>
              <p className='color-stack text-block justify-text mt-3'>5 cotizaciones para el transporte de carros son más que suficientes para comparar. Elimina el trabajo de llamar a  varios transportistas, ellos te llamarán rápidamente y tu escoges el que más te parezca.</p>

              <h3 className='color-stack text-block fw-bold'>Ahorra mas</h3>
              <p className='color-stack text-block mt-3 justify-text'>Con tarifas desde $249.00 dependiendo del destino, entre los transportistas compiten para darte el mejor precio y servicio.</p>
            </div>
          </Col>
          <Col md={8} xs={12}>
            <div className="image-stack mt-5 p-5">
              <Image src="/images/ccuadro.png" className="responsive-image mt-5" />
            </div>
          </Col>
        </Row>

      </div>
      <div className='g-0 bg-imagestack d-block d-md-none' id='imagestack'>
        <Row className="align-items-center g-0">
          <Col md={4} xs={12}>
            <div className="text-block px-4">
            <h3 className='color-stack text-block justify-text mt-md-0 mt-5' ><span className='fw-bold'>¿</span>POR QUE CON <span className='fw-bold'>MUEVEMICARRO?</span></h3>
              <h3 className='color-stack text-block mt-2 fw-bold'>Rapido & Facil</h3>
              <p className='color-stack text-block justify-text mt-3'>5 cotizaciones para el transporte de carros son más que suficientes para comparar. Elimina el trabajo de llamar a  varios transportistas, ellos te llamarán rápidamente y tu escoges el que más te parezca.</p>
                <Col md={8} xs={12}>
                  <div className="image-stack p-5">
                    <Image src="/images/ccuadro.png" className="img-fluid" />
                  </div>
                </Col>
              <h3 className='color-stack text-block fw-bold'>Ahorra mas</h3>
              <p className='color-stack text-block mt-3 justify-text'>Con tarifas desde $249.00 dependiendo del destino, entre los transportistas compiten para darte el mejor precio y servicio.</p>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ImageStack;
