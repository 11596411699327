import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavComp from './components/Nav';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import ImageStack from './components/ImageStack';
import InfoCol from './components/InfoCol';
import Steps from './components/Steps';
import Footer from './components/Footer';
import Terms from './components/Terms';
import Gracias from './components/Gracias';
import { useEffect } from 'react';



function App() {
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://cdn.bitrix24.com/b2514537/crm/site_button/loader_14_8f1bsx.js?' + (Date.now() / 60000 | 0);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Router>
    <div className="App">
        <NavComp/>
        <Routes>
        <Route path="/" element={<>
            <Home/>
            <ImageStack/>
            <InfoCol/>
            <Steps/>
            </>} />
            <Route path='/terms'element={<Terms/>}/>
            <Route path='/gracias'element={<Gracias/>}/>
      </Routes>
        <Footer/>
      </div>
      </Router>
  );
}

export default App;
