import React, { useEffect } from 'react';

export default function Gracias() {
    useEffect(() => {
      if (window.gtag) {
        window.gtag('event', 'conversion', {'send_to': 'AW-877483091/JZWACOKKvZ0ZENOotaID'})
      }
    }, []);
    return (
        <section
        style={{
          backgroundImage: `url(/images/thanksbg.jpg)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          paddingTop: '20rem',
          backgroundColor: '#001338',
          paddingBottom: '20rem',
          position: 'relative', // Make the section a relative container
          overflow: 'hidden', // Prevents any absolutely positioned child from overflowing
        }}
      >
    {/* Overlay */}
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark transparent overlay
        zIndex: 1, // Sits above the background image
      }}
    />

      <div className='text-white' style={{
          position: 'relative', // This ensures content sits above the overlay
          zIndex: 2, // Higher zIndex than the overlay
          color: 'white'
        }}>
        <div className=' mx-auto text-center text-white section-content'>
          <h1 className='text-white'>
            ¡Gracias por contactarnos!
          </h1>
          <h1 className='my-3 fs-3 fw-bold text-white'>
            SOLICITUD RECIBIDA
          </h1>
          <p className='lead px-3 mb-0'>
            Si deseas cotizar otro vehículo o saber mas de nosotros puedes volver a la pagina principal
          </p>
          <a className='btn btn-primary rounded mt-5' href='/'>Volver al inicio</a>
        </div>
      </div>
  </section>

    )
}