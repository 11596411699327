import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // Asumiendo que estás usando react-router para la navegación

const Footer = () => {
  return (
    <footer className="footer bg-footer g-0 " style={{marginTop: '-2px'}} id='footer'>
      <div className="text-center">
        {/* Logo */}
        <Row className="justify-content-center mb-4 pt-5 g-0">
          <Col xs={12}>
            <Image src="/images/logo.svg" style={{ maxWidth: '100px' }} />
          </Col>
        </Row>

        {/* Enlaces de navegación */}
        <Row className="justify-content-center mb-4 g-0">
          {/* Primera Columna de Enlaces */}
          <Col xs={6} md={3}>
            <Link to="/sobre-nosotros" className="d-block text-white mb-2" style={{ textDecoration: 'none'}}>Sobre Nosotros</Link>
            <Link to="/terminos-y-condiciones" className="d-block text-white text-block mb-2" style={{ textDecoration: 'none'}}>Términos y Condiciones</Link>
            <Link to="/politica-y-privacidad" className="d-block text-white text-block mb-2" style={{ textDecoration: 'none'}}>Política y Privacidad</Link>
          </Col>
          {/* Segunda Columna de Enlaces */}
          <Col xs={6} md={3}>
              <Link to="/Informacion" className="d-block text-white text-block mb-2" style={{ textDecoration: 'none' }}>Informacion</Link>
              <Link to="/telefono" className="d-block text-white text-block mb-2" style={{ textDecoration: 'none' }}>Teléfono: +1 870-455-6742</Link>
              <Link to="/correo" className="text-white text-block" style={{ textDecoration: 'none' }}>info@muevemicarro.com</Link>
              <a href="https://www.instagram.com/tucpamerica/" target="_blank" rel="noreferrer" className="ms-2">
                  <img width="24" alt="Instagram logo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/2048px-Instagram_logo_2016.svg.png" height="24" />
              </a>
          </Col>

        </Row>

        {/* Derechos reservados */}
        <Row className="justify-content-center g-0">
          <Col xs={12} className='text-white text-block'>
            Muevemicarro.com 2024 todos los derechos reservados
          </Col>
        </Row>
      </div>
    </footer>
  );
};

export default Footer;

