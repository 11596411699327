import React from "react";
import { Row, Col, Image, Button } from "react-bootstrap";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

const InfoCol = () => {
  return (
    <div className="g-0" id="Infocol">
      <Row className="bg-imagestack g-0">
        <Col md={8}>
          <div className="main-image-container justify-content-end">
            <Image src="/images/top.png" fluid />
          </div>
        </Col>
        <Col md={4} className="mt-5 px-5 g-0 d-none d-md-block">
          {" "}
          {/* Ajusta los tamaños de las columnas según sea necesario */}
          <h3 className="color-stack text-block mt-2 fw-bold">100% Free</h3>
          <p className="color-stack text-block mt-3 justify-text">
            Somos expertos en conectar transportistas con clientes, te llamarán
            transportistas en tu idioma que sirven tus rutas diariamente.
          </p>
          <Button
            variant="primary"
            className="bg-button texte-center text-block py-2"
          >
            Solicitar Cotizacion
          </Button>
        </Col>
      </Row>
      <Row className="g-0">
        <Col md={4}></Col>
        <Col md={4} className="rectangle-column g-0 ">
          <div className="rectangle1"></div>
        </Col>
        <Col md={4}></Col>
      </Row>

      <Row className="g-0">
        <Col md={4}></Col>
        <Col md={4} className="rectangle-column mt-3 g-0">
          <div className="rectangle2"></div>
        </Col>
        <Col md={4}></Col>
      </Row>

      <Row className="g-0">
        <Col
          md={4}
          className="d-none d-md-flex flex-column justify-content-center align-items-center g-0"
        >
          <Image
            src="/images/cuadro6.png"
            style={{ maxWidth: "80%", marginBottom: "0.5rem" }}
          />
          <Image src="/images/cuadro7.png" style={{ maxWidth: "80%" }} />
        </Col>

        <Col md={4} className="rectangle-column mt-3 g-0">
          <div className="rectangle3 d-flex justify-content-between">
            <div className="rectangle3 d-flex flex-column align-items-center">
              {/* Bloque para la imagen 1 con título y texto */}
              <div className="d-flex align-items-center mb-2 image-text-wrapper px-3">
                <div>
                  <h2 className="image-title text-block text-white fw-bold">
                    <VisibilitySensor
                      partialVisibility
                      offset={{ bottom: 200 }}
                    >
                      {({ isVisible }) => (
                        <CountUp
                          end={isVisible ? 45 : 0}
                          duration={2.75}
                          suffix="%"
                        />
                      )}
                    </VisibilitySensor>
                    +
                  </h2>
                  <span className="image-text text-block text-white me-5 fw-bold">
                    ¡Promedio de ahorro!
                  </span>
                </div>
                <Image src="/images/cuadro1.png" className=" mb-4 " />
              </div>
              {/* ... otros bloques de imagen con texto */}

              <div className="d-flex align-items-center mb-2 image-text-wrapper px-3">
                <div>
                  <h2 className="image-title text-block text-white fw-bold">
                    <VisibilitySensor
                      partialVisibility
                      offset={{ bottom: 200 }}
                    >
                      {({ isVisible }) => (
                        <CountUp
                          end={isVisible ? 5 : 0}
                          duration={2.75}
                          suffix="min"
                        />
                      )}
                    </VisibilitySensor>
                  </h2>
                  <span className="image-text text-block text-white fw-bold">
                    En recibir una cotizacion
                  </span>
                </div>
                <Image src="/images/cuadro2.png" className="ml-2 mb-4" />
              </div>

              <div className="d-flex align-items-center mb-2 image-text-wrapper px-3">
                <div>
                  <h2 className="image-title text-block text-white fw-bold">
                    <VisibilitySensor
                      partialVisibility
                      offset={{ bottom: 200 }}
                    >
                      {({ isVisible }) => (
                        <CountUp
                          end={isVisible ? 350 : 0}
                          duration={2.75}
                          prefix="+"
                        />
                      )}
                    </VisibilitySensor>
                  </h2>
                  <span className="image-text text-block text-white fw-bold">
                    Transportistas licenciados
                  </span>
                </div>
                <Image src="/images/cuadro3.png" className="ml-2 mb-4" />
              </div>

              <div className="d-flex align-items-center mb-2 image-text-wrapper px-3">
                <div>
                  <h2 className="image-title text-block text-white fw-bold">
                    <VisibilitySensor
                      partialVisibility
                      offset={{ bottom: 100 }}
                    >
                      {({ isVisible }) => (
                        <CountUp
                          end={isVisible ? 50000 : 0}
                          duration={2.75}
                          prefix="+"
                        />
                      )}
                    </VisibilitySensor>
                  </h2>
                  <span className="image-text text-block text-white me-5 fw-bold">
                    cotizaciones enviadas
                  </span>
                </div>
                <Image src="/images/cuadro4.png" className="ml-2 mb-4" />
              </div>
            </div>
          </div>
        </Col>
        <Col
          md={4}
          className="d-none d-md-flex flex-column justify-content-center align-items-center g-0"
        >
          <div className="ms-2 img-fluid">
            <Image
              src="/images/video.jpg"
              style={{ width: "100%", height: "auto" }}
            />
          </div>
          <h3 className="image-title text-block color-stack mt-4 text-center">
            ¡Servicio Especializado en Transporte de Carros!
          </h3>
          <p className="color-stack text-block mt-3 justify-text">
            Sin Complicaciones en Estados Unidos y Puerto Rico
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default InfoCol;
